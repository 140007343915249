import React, {useEffect} from "react";
import FilterIcon from "../../assets/svg/filter.svg";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox} from "./PartialsComponent";
import {DRONE_DETAIL_OPEN, OBJECT_DETAIL_OPEN} from "../../store/actionsName";

const Config = require(`./../../../config/${process.env.REACT_APP_CONFIG}`).default;
const Types = require(`./../../../config/${process.env.REACT_APP_CONFIG}`).Types;

const Filter = () => {
    const {currentMap, filteredPowerLines, filteredPoints} = useSelector((state) => state.mapReducer);
    const dispatch = useDispatch();

    const handleChecked = (e) => {
        if (isChecked(e.target.value)) {
            dispatch({type: "FILTERED_POINTS", payload: filteredPoints.filter(type => type !== e.target.value)});
        } else {
            dispatch({type: "FILTERED_POINTS", payload: [...filteredPoints, e.target.value]});
        }
    }

    const handleCheckedPowerLine = (e) => {
        let selectedFilterPoints = [];
        Types.filter(t => t.powerName === e.target.value).forEach(f => selectedFilterPoints.push(f.className))

        if (isCheckedPowerLine(e.target.value)) {
            dispatch({
                type: "FILTERED_POWER_LINES",
                payload: filteredPowerLines.filter(type => type !== e.target.value)
            });
            let newFilteredPoints = filteredPoints;
            selectedFilterPoints.forEach((s) => {
                newFilteredPoints = newFilteredPoints.filter(f => f !== s)
                dispatch({type: "FILTERED_POINTS", payload: newFilteredPoints})
            })
        } else {
            dispatch({type: "FILTERED_POWER_LINES", payload: [...filteredPowerLines, e.target.value]});
            dispatch({type: 'FILTERED_POINTS', payload: [...filteredPoints, ...selectedFilterPoints]})
        }
    }

    const isChecked = (value) => {
        return Boolean(filteredPoints.find(type => type === value));
    }
    const isCheckedPowerLine = (value) => {
        return Boolean(filteredPowerLines.find(type => type === value));
    }

    const handleAllChecked = () => {
        if (filteredPoints.length > 0) {
            checkReset()
        } else {
            let allChecked = [];
            Types.forEach(t => {
                allChecked.push(t.className)
            })
            dispatch({type: "FILTERED_POINTS", payload: allChecked});
        }
    }

    const handleAllCheckedPowerLines = () => {
        if (filteredPowerLines.length > 0) {
            checkResetPowerFilter()
            checkReset()
        } else {
            handleAllChecked()
            const allCheckedPowerLine = ['Düşük gerilim', 'Orta gerilim', 'Yüksek gerilim']
            dispatch({type: 'FILTERED_POWER_LINES', payload: allCheckedPowerLine})
        }
    }

    const checkReset = () => dispatch({type: "FILTERED_POINTS", payload: []})
    const checkResetPowerFilter = () => {
        dispatch({type: "FILTERED_POWER_LINES", payload: []})
        dispatch({type: "FILTERED_POINTS", payload: []})
    }

    useEffect(() => {
        const filter = ['match', ['get', 'class_code'], [...filteredPoints], true, false];
        const filterPowerLine = ['match', ['get', 'category'], [...filteredPoints], true, false];
        if (currentMap) {
            currentMap.setFilter("nqfgcw4hju-objects-v5", filteredPoints.length ? filter : null);
            if (Config.api.powerLineAPI)
                currentMap.setFilter("power-lines", filteredPoints.length ? filterPowerLine : null);
        }

    }, [filteredPoints, currentMap]);

    const clearScreen = () => {
        dispatch({type: OBJECT_DETAIL_OPEN, payload: false});
        dispatch({type: DRONE_DETAIL_OPEN, payload: false});
    }

    const getPowerLineFilters = () => {
        return powerlineFilter.map((t) => {
            return (
                <li className="filter-list-powerline-item" key={t.id}>
                    <Checkbox
                        text={t.name}
                        value={t.name}
                        colorName={t.color}
                        checked={isCheckedPowerLine(t.name)}
                        onChange={(e) => handleCheckedPowerLine(e)}
                    />
                </li>
            )
        })
    }

    const powerlineFilter = [
        {
            id: 1,
            name: 'Düşük gerilim',
            color: '#56C568'
        },
        {
            id: 2,
            name: 'Orta gerilim',
            color: '#FFC400'
        },
        {
            id: 3,
            name: 'Yüksek gerilim',
            color: '#EB5757'
        }
    ]


    return (
        <div className={"btn-container"}>
            <div tabIndex={0} style={{backgroundColor: filteredPoints.length ? '#fbc203' : ''}}
                 className={`btn btn-filter`} onClick={clearScreen}>
                <img src={FilterIcon} alt={"Filter Icon"}/>


                <div className={`filter-container`}>
                    <p className={"filter-container-title"}>Filtreler</p>
                    <div className="filter-container-object-header">
                        <Checkbox
                            text={'Nesne'}
                            checked={filteredPoints.length > 0 && true}
                            onChange={() => handleAllChecked()}
                        />
                        {filteredPoints.length > 0 && <p onClick={() => checkReset()}>Temizle X</p>}
                    </div>
                    <div>
                        <ul>
                            {Types.map((type, index) => {
                                return (
                                    <li className={"filter-list-item"} key={index}>
                                        <Checkbox
                                            value={type.className}
                                            icon={type.iconName}
                                            text={type.fullName}
                                            checked={isChecked(type.className)}
                                            onChange={(e) => handleChecked(e)}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    {Config.api.powerLineAPI && (
                        <div>
                            <div style={{marginTop: '20px'}} className="filter-container-powerline-header">
                                <Checkbox
                                    text={'Enerji hattı'}
                                    checked={filteredPowerLines.length > 0 && true}
                                    onChange={() => handleAllCheckedPowerLines()}
                                />
                                {filteredPowerLines.length > 0 && <p onClick={() => checkResetPowerFilter()}>Temizle X</p>}
                            </div>
                            <div style={{paddingRight: '10px', marginTop: '15px'}}>
                                <ul>
                                    {getPowerLineFilters()}
                                </ul>
                            </div>
                        </div>
                    )}


                </div>
            </div>

        </div>
    );
};

export default Filter;
