import {FILTERED_POINTS, MAP_CURRENT, UPDATE_POINT_TYPE,OBJECT_DETAIL_OPEN,DRONE_DETAIL_OPEN,FILTERED_POWER_LINES} from "../../actionsName";

const accessToken = require(`./../../../../config/${process.env.REACT_APP_CONFIG}`).accessToken;
const urlToken = require(`./../../../../config/${process.env.REACT_APP_CONFIG}`).urlToken;

const INITIAL_STATE = {
  mapStyle: {
    style: `${process.env.REACT_APP_API_MAP_URL}${urlToken}`,
    accessToken: accessToken,
  },
  currentMap: null,
  carPoints: true,
  objectDetailPopup:false,
  droneDetailPopup:false,
  filteredPoints: [],
  filteredPowerLines:[]
};

const mapReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MAP_CURRENT:
      return { ...state, currentMap: action.payload };
    case UPDATE_POINT_TYPE:
      return { ...state, carPoints: action.payload };
    case FILTERED_POINTS:
      return { ...state, filteredPoints: action.payload };
    case FILTERED_POWER_LINES:
      return { ...state, filteredPowerLines: action.payload };
    case OBJECT_DETAIL_OPEN:
      return { ...state, objectDetailPopup: action.payload };
    case DRONE_DETAIL_OPEN:
      return { ...state, droneDetailPopup: action.payload };
    default:
      return state;
  }
};

export default mapReducer;
