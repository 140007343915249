export const urlToken = "/sezerrb/cljqxorib010o01o48xf07tkq";
export const accessToken =
  "pk.eyJ1Ijoic2V6ZXJyYiIsImEiOiJjbGpxeGgwb2kwYnVoM3BwNzNtbHJseWtkIn0._nt_1FMqbSE7vMjmhNaCcQ";

export const Types = [
    {
        className: "inst-utility-pole-low-voltage-wood",
        iconName: require("../../src/enerjisaComponents/assets/svg/inst-utility-pole-low-voltage-wood.svg"),
        fullName: "Alçak Gerilim Ağaç",
        powerName: 'Düşük gerilim',
        color: '#56C568',
    },
    {
        className: "inst-utility-pole-low-voltage-iron",
        iconName: require("../../src/enerjisaComponents/assets/svg/inst-utility-pole-low-voltage-iron.svg"),
        fullName: "Alçak Gerilim Demir",
        powerName: 'Düşük gerilim',
        color: '#56C568'
    },
    {
        className: "inst-utility-pole-low-voltage-concrete",
        iconName: require("../../src/enerjisaComponents/assets/svg/inst-utility-pole-low-voltage-concrote.svg"),
        fullName: "Alçak Gerilim Beton",
        powerName: 'Düşük gerilim',
        color: '#56C568'
    },
    {
        className: "inst-utility-pole-common-iron",
        iconName: require("../enerjisaComponents/assets/svg/inst-utility-pole-common-iron.svg"),
        fullName: "Müşterek Demir",
        powerName: 'Orta gerilim',
        color: '#FFC400'
    },
    {
        className: "inst-utility-pole-transformers-iron",
        iconName: require("../../src/enerjisaComponents/assets/svg/inst-utility-pole-transformers-iron.svg"),
        fullName: "Trafo Direği",
        powerName: 'Orta gerilim',
        color: '#FFC400'
    },
    {
        className: "inst-utility-pole-medium-voltage-iron",
        iconName: require("../../src/enerjisaComponents/assets/svg/inst-utility-pole-medium-voltage-iron.svg"),
        fullName: "Orta Gerilim Demir",
        powerName: 'Orta gerilim',
        color: '#FFC400'
    },
    {
        className: "inst-utility-pole-high-voltage-iron",
        iconName: require("../../src/enerjisaComponents/assets/svg/inst-utility-pole-high-voltage-iron.svg"),
        fullName: "Yüksek Gerilim Demir",
        powerName: 'Yüksek gerilim',
        color: '#EB5757'
    },
    {
        className: "inst-utility-pole-info-plate",
        iconName: require("../../src/enerjisaComponents/assets/svg/inst-utility-pole-Information.svg"),
        fullName: "Uyarı Levhası",
    },
    {
        className: "inst-utility-pole-medium-concrete",
        iconName: require("../../src/enerjisaComponents/assets/svg/inst-utility-pole-medium-concrete.svg"),
        fullName: "Orta Gerilim Beton",
        powerName: 'Orta gerilim',
        color: '#FFC400'
    },
    {
        className: "inst-utility-pole-common-concrete",
        iconName: require("../../src/enerjisaComponents/assets/svg/inst-utility-pole-common-concrete.svg"),
        fullName: "Müşterek Beton",
        powerName: 'Orta gerilim',
        color: '#FFC400'
    },
    {
        className: "inst-tree",
        iconName: require("../../src/enerjisaComponents/assets/svg/inst-tree.svg"),
        fullName: "Ağaç",
    },
];

export const projectInfo = {
    componentToggles: {
        studioDatas: false,
    },
    excludedKeys: [
        "area",
        "class_code",
        "confidence",
        "created_by_id",
        "filename",
        "group_id",
        "height",
        "id",
        "img_code",
        "match_id",
        "width",
        "matchedPoints",
        "organization_key",
        "project_key",
    ],
    title: "Enerjisa - Türkiye'nin Enerjisi",
    startCoord: [29.257639, 40.879848],
    isDroneProject: true,
    isControl:true,
    isWelcomeModal:true,
    isReport:true,
    isLogin: true,
    isSatellite: true,
    api: {
        powerLineAPI:
            "/geoserver/visiosoft/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=visiosoft%3Aenerjisa_power_line&outputFormat=application%2Fjson",
        lineAPI:
            "/mapilio/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mapilio:map_roads_line&CQL_FILTER=project_key=%27NqFgcW4HJu%27&outputFormat=application%2Fjson",
        pointAPI:
            "/mapilio/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mapilio:map_points&CQL_FILTER=project_key=%27NqFgcW4HJu%27&outputFormat=application%2Fjson",
        dronePointAPI:
            "/geoserver/mapilio/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mapilio%3Adrone&outputFormat=application%2Fjson",
        symbolAPI:
            "/mapilio/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mapilio:enerjisa_feature_list&CQL_FILTER=project_key=%27NqFgcW4HJu%27&outputFormat=application%2Fjson",
        nearTreesAPI:
            "/geoserver/visiosoft/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=visiosoft%3Aenerjisa_yakin_agaclar&outputFormat=application%2Fjson",
    },
};

export default projectInfo;
