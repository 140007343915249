import '../css/Auth.scss'
import {AuthBackground} from "../assets/svg";
import React, {useEffect} from "react";
import {EnerjisaWhiteLogo} from "../assets/svg";
import {useNavigate} from "react-router";

const AuthLayout = ({children}) => {
    const navigate=useNavigate()

    useEffect(()=>{
        if(localStorage.getItem('auth')){
            return navigate('/map')
        }
    },[])

    return (
        <div className={'auth-layout'}>
            <div className={'left'}>
                <img className={"background-img"} src={AuthBackground} alt={"background"}/>
                <div className="auth-info">
                    <img src={EnerjisaWhiteLogo} alt="enerjisa"/>
                    <h1>Envanter Yönetim Sistemi</h1>
                    <h3>Tespit edilen objeler ve Raporlama</h3>
                    <p className="info">
                        <span>info@enerjisa.com</span>
                        <span>444 4 372</span>
                    </p>
                </div>
            </div>
            <div className={'right'}>
                <div className="center">
                    {children}
                </div>
            </div>
        </div>
    )
}
export default AuthLayout