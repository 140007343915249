import {SET_MESSAGE} from "../store/actionsName";
import {store} from "../store/store";


const showMessage=(message,type)=>store.dispatch({type:SET_MESSAGE,payload:{status:true,message:message,type:type}})

const closeMessage=()=>store.dispatch({type:SET_MESSAGE,payload:{status:false,message:'',type:null}})

export const Message={

    success:(message)=>showMessage(message,'success'),

    info:(message)=>showMessage(message,'info'),

    close:()=>closeMessage()
}