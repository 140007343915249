import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import ReactMapboxGl, {ZoomControl} from "react-mapbox-gl";
import MapTraffic from "./MapTraffic";
import {MapboxTraffic} from "./MapTraffic";
import mapboxgl from "mapbox-gl";
import {Info, MapHeader, ObjectDetail, ToastMessage} from "./components";
import "../css/Map.css";
import {fetchHandler, flyHandler} from "../helper/helper";
import {useDispatch, useSelector} from "react-redux";
import {DRONE_DETAIL_OPEN, MAP_CURRENT, OBJECT_DETAIL_OPEN} from "../store/actionsName";
import ObjectDetailDrone from "./components/ObjectDetailDrone";
import MapImage from "../assets/img/satallite.png";
import StreetMap from "../assets/img/streetmap.jpg";

mapboxgl.workerClass =
    // eslint-disable-next-line import/no-webpack-loader-syntax
    require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const Map = () => {
    const Config = require(`./../../config/${process.env.REACT_APP_CONFIG}`).default;
    const Types = require(`./../../config/${process.env.REACT_APP_CONFIG}`).Types;
    const accessToken = require(`./../../config/${process.env.REACT_APP_CONFIG}`).accessToken;
    const urlToken = require(`./../../config/${process.env.REACT_APP_CONFIG}`).urlToken;
    const center = Config.startCoord;
    let appMap = useRef(null);
    const [mapReady, setMapReady] = useState(false);
    const [selectedImage, setSelectedImage] = useState({});
    const [selectedImageDrone, setSelectedImageDrone] = useState({});
    const [selectedDetail, setSelectedDetail] = useState({});
    const [pointsArr, setPointsArr] = useState([]);
    const [addedSymbols, setAddedSymbols] = useState([]);
    const [isSatallite, setSatallite] = useState(false);
    const {carPoints, objectDetailPopup, droneDetailPopup} = useSelector((state) => state.mapReducer);
    const dispatch = useDispatch();
    const [isInfoShown, setIsInfoShown] = useState(true)
    let popup = null;
    popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        offset: 25,
    });
    let points = [];
    let addedImage = false;
    const Mapbox = ReactMapboxGl({
        accessToken: accessToken,
        preserveDrawingBuffer: true,
        minZoom: 1,
    });

    const getBBOX = (point) => {
        const matchedPoints = JSON.parse(point.features[0].properties.matched_points);
        let bbox = [0, []];

        matchedPoints.forEach((point) => {
            const scores = [point.properties.score_1, point.properties.score_2]
            const _bbox = [point.properties.bbox_1, point.properties.bbox_2]
            const max = Math.max(...scores)
            const index = scores.indexOf(max);

            if (max > bbox[0]) {
                bbox = [max, _bbox[index]]
            }
        })

        return bbox[1];
    }

    const bboxScale = (point) => {
        const bbox = getBBOX(point);
        const [x_min, y_min, x_max, y_max] = bbox;

        bbox.height = bbox[3] - bbox[1];
        bbox.width = bbox[2] - bbox[0];

        let scale_height = 0;
        let scale_width = 0;

        if (bbox.height > bbox.width) {
            scale_width = (bbox.height - bbox.width) / 2;
        } else {
            scale_height = (bbox.width - bbox.height) / 2;
        }

        const spaceBbox = {
            left: (x_min > scale_width) ? scale_width : 0,
            top: (y_min > scale_height) ? scale_height : 0,
            width: ((4096 - x_max) > scale_width) ? scale_width : 4096 - x_max,
            height: ((2048 - y_max) > scale_height) ? scale_height : 2048 - y_max,
        };

        return {
            xmin: x_min - spaceBbox.left,
            ymin: y_min - spaceBbox.top,
            xmax: Number(x_max) + spaceBbox.width,
            ymax: Number(y_max) + spaceBbox.height,
        }
    }

    useEffect(() => {
        if (mapReady && Config.api.powerLineAPI) {
            addPowerLines(appMap.current)
        }
    }, [mapReady])

    useEffect(() => {
        if (appMap.current && mapReady) {
            // addEnerjisaPoints(appMap.current)
            dispatch({type: OBJECT_DETAIL_OPEN, payload: false});
            dispatch({type: DRONE_DETAIL_OPEN, payload: false});

            if (carPoints) {
                if (!Config.componentToggles.studioDatas) {
                    addPoints(appMap.current);
                } else {
                    if (appMap.current.getLayer("layer-drone-point")) {
                        appMap.current.removeLayer("layer-drone-point");
                        flyHandler([29.10569, 41.132719], 8);
                    }
                    appMap.current.setLayoutProperty(
                        "nqfgcw4hju-points",
                        "visibility",
                        "visible"
                    );
                    appMap.current.setLayoutProperty(
                        "nqfgcw4hju-roads",
                        "visibility",
                        "visible"
                    );
                    appMap.current.setLayoutProperty(
                        "nqfgcw4hju-objects-v5",
                        "visibility",
                        "visible"
                    );
                    if (Config.api.powerLineAPI) {
                        appMap.current.setLayoutProperty(
                            "power-lines",
                            "visibility",
                            "visible"
                        );
                    }
                    flyHandler([29.10569, 41.132719], 8);
                }
            } else {
                appMap.current.setLayoutProperty(
                    "nqfgcw4hju-objects-v5",
                    "visibility",
                    "none"
                );
                if (Config.api.powerLineAPI) {
                    appMap.current.setLayoutProperty(
                        "power-lines",
                        "visibility",
                        "none"
                    );
                }

                addDronePoints(appMap.current);
                flyHandler([32.8597, 39.9334], 8);
            }
        }
    }, [carPoints, appMap.current, mapReady]);

    const addLines = (map) => {
        fetchHandler(`${process.env.REACT_APP_API_GEOJSON_URL}${Config.api.lineAPI}`).then((result) => {
            if (!map.getLayer("layer-line")) {
                if (!map.getSource("layer-line")) {
                    map.addSource("layer-line", {
                        type: "geojson",
                        data: result,
                    });
                }
                map.addLayer({
                    id: "layer-line",
                    type: "line",
                    source: "layer-line",
                    layout: {
                        "line-join": "round",
                        "line-cap": "round",
                    },
                    paint: {
                        "line-color": "#0074E9",
                        "line-width": 4,
                    },
                });
            }
        });
    };

    const addPowerLines = (map) => {
        fetchHandler(`${process.env.REACT_APP_API_GEOJSON_URL}${Config.api.powerLineAPI}`).then((result) => {
            result.features.forEach(response => {
                response.properties = {
                    ...response.properties,
                    color: Types.filter(type => type.className === response.properties.category)[0].color
                }
            })
            map.addLayer({
                id: 'power-lines',
                type: "line",
                'source': {
                    'type': 'geojson',
                    'data': result
                },
                paint: {
                    "line-color": ['get', 'color'],
                    "line-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        6,
                        0,
                        8,
                        2,
                        12,
                        4,
                    ],
                    "line-dasharray": [1, 1]
                }
            })
        }).finally(() => enableLineAnimation('power-lines', map))
    }
    const animationStep = 50
    const enableLineAnimation = (layerId, map) => {
        var step = 0;
        let dashArraySeq = [
            [0, 4, 3],
            [1, 4, 2],
            [2, 4, 1],
            [3, 4, 0],
            [0, 1, 3, 3],
            [0, 2, 3, 2],
            [0, 3, 3, 1]
        ];
        setInterval(() => {
            step = (step + 1) % dashArraySeq.length;
            map.setPaintProperty(layerId, 'line-dasharray', dashArraySeq[step]);
        }, animationStep);
    }

    const addEnerjisaPoints = (map) => {
        fetchHandler(`https://geo.mapilio.com/geoserver/visiosoft/wms?service=WMS&version=1.1.0&request=GetMap&layers=visiosoft%3Aenerjisa_sofular_ground&bbox=29.3522834777832%2C41.1810493469238%2C29.4901351928711%2C41.2043495178223&width=768&height=330&srs=EPSG%3A4326&styles=&format=geojson`).then((result) => {
            setPointsArr(result.features);
            points = result.features;
            if (!map.getLayer("enerjisa-point")) {
                if (!map.getSource("enerjisa-point")) {
                    map.addSource("enerjisa-point", {
                        type: "geojson",
                        data: result,
                    });
                }
                map.addLayer({
                    id: "enerjisa",
                    type: "circle",
                    source: "enerjisa-point",
                    paint: {
                        "circle-radius": [
                            "interpolate",
                            ["linear"],
                            ["zoom"],
                            7.9,
                            4,
                            8,
                            0,
                            14,
                            0,
                            14.1,
                            5,
                        ],
                        "circle-blur": [
                            "interpolate",
                            ["linear"],
                            ["zoom"],
                            14,
                            0,
                            22,
                            0.5,
                        ],
                        "circle-color": "#ff9000",
                    },
                });
            }
        });
    };


    const addPoints = (map) => {
        if (map.getLayer("layer-drone-point")) {
            map.removeLayer("layer-drone-point");
        }
        if (!map.getLayer("layer-line")) {
            addLines(map);
        }
        fetchHandler(`${process.env.REACT_APP_API_GEOJSON_URL}${Config.api.pointAPI}`).then((result) => {
            setPointsArr(result.features);
            points = result.features;
            if (!map.getLayer("layer-point")) {
                if (!map.getSource("layer-point")) {
                    map.addSource("layer-point", {
                        type: "geojson",
                        data: result,
                    });
                }
                map.addLayer({
                    id: "layer-point",
                    type: "circle",
                    source: "layer-point",
                    paint: {
                        "circle-radius": [
                            "interpolate",
                            ["linear"],
                            ["zoom"],
                            7.9,
                            4,
                            8,
                            0,
                            14,
                            0,
                            14.1,
                            5,
                        ],
                        "circle-blur": [
                            "interpolate",
                            ["linear"],
                            ["zoom"],
                            14,
                            0,
                            22,
                            0.5,
                        ],
                        "circle-color": "#146aff",
                    },
                });
                map.addLayer({
                    id: "layer-point-buffer",
                    type: "circle",
                    source: "layer-point",
                    paint: {
                        "circle-color": "#146aff",
                        "circle-stroke-color": "#146aff",
                        "circle-stroke-opacity": 0.4,
                        "circle-stroke-width": [
                            "interpolate",
                            ["linear"],
                            ["zoom"],
                            7,
                            1,
                            7.1,
                            0,
                            14,
                            0,
                            14.1,
                            2,
                        ],
                    },
                });
            }
        });
    };

    const addDronePoints = (map) => {
        if (Config.isDroneProject) {
            if (map.getLayer("layer-point")) {
                map.removeLayer("layer-point");
                map.removeLayer("layer-line");
            }
            if (map.getLayer("nqfgcw4hju-points")) {
                map.setLayoutProperty("nqfgcw4hju-points", "visibility", "none");
                map.setLayoutProperty("nqfgcw4hju-roads", "visibility", "none");
            }
            fetchHandler(
                `${process.env.REACT_APP_API_GEOJSON_URL}${Config.api.dronePointAPI}`
            ).then((result) => {
                setPointsArr(result.features);
                points = result.features;
                if (!map.getLayer("layer-drone-point")) {
                    if (!map.getSource("layer-drone-point")) {
                        map.addSource("layer-drone-point", {
                            type: "geojson",
                            data: result,
                        });
                    }
                    points.forEach(dronePoint => {
                        map.addLayer({
                            id: "layer-drone-point",
                            type: "symbol",
                            source: "layer-drone-point",
                            layout: {
                                "icon-image": dronePoint.id,
                                "icon-size": 1,
                            },
                        });
                        map.loadImage(
                            require(`../assets/img/inst-drone-point.png`),
                            (error, image) => {
                                if (error) throw error;
                                if (image !== undefined) {
                                    map.addImage(dronePoint.id, image);
                                }
                            }
                        );
                    })
                }
            });
        }
    };

    const addSymbols = (map) => {
        fetchHandler(
            `${process.env.REACT_APP_API_GEOJSON_URL}${Config.api.symbolAPI}`
        ).then((result) => {
            if (!map.getLayer("layer-symbols")) {
                result.features = result.features.filter(
                    (res) => res.properties.class_code !== undefined
                );
                if (!map.getSource("layer-symbols")) {
                    map.addSource("layer-symbols", {
                        type: "geojson",
                        data: result,
                    });
                }
                let newFeatures = result.features.filter(
                    (res) => res.properties.class_code !== undefined
                );
                setAddedSymbols(newFeatures);
                newFeatures.forEach((point) => {
                    map.addLayer({
                        id: `objects-${point.properties.class_code}`,
                        type: "symbol",
                        source: "layer-symbols",
                        filter: ["==", "class_code", point.properties.class_code],
                        layout: {
                            "icon-image": point.id,
                            "icon-size": 0.75,
                        },
                    });
                    map.loadImage(
                        require(`../assets/img/${point.properties.class_code}.png`),
                        (error, image) => {
                            if (error) throw error;
                            if (image !== undefined) {
                                map.addImage(point.id, image);
                            }
                        }
                    );
                });
                const hoverHandler = (e, features, id2, image) => {
                    let properties = features.properties;
                    let keys = Object.keys(properties);
                    keys = keys.filter((val) => !Config.excludedKeys.includes(val));
                    const coordinates = features.geometry.coordinates.slice();
                    const test = () =>
                        keys.map((key) => {
                            return `
                <p style="height: min-content; font-size: 14px; margin-top: 10px; margin-bottom: 5px; margin-left:10px; text-transform: capitalize; color: #616366; white-space: nowrap;">
                ${properties[key]}
                </p>
                `;
                        });
                    const html = `
          <div style="border-radius:8px; padding:0;">
             <img id="image" src=${image}
             onload="${PreloadImage(image)}"
              style="object-fit:cover; object-position:center; display:none;" width="98px" height="170px;"  />
              <div>
              <p style="height: min-content; font-size: 16px; font-weight: 500; margin-top: 10px; margin-bottom: 5px; margin-left:10px; text-transform: capitalize;">${
                        properties.classname
                        }</p>
                ${test()}
              <span style="margin-left:10px; font-size: 10px; color: #C0C3C8;">${coordinates[1]} ,${coordinates[0]} </span>
              <p
              style="
                padding: 5px 10px;
                background-color: #75B505;
                border-radius: 16px;
                font-size: 12px;
                color: #FFF;
                height: min-content;
                white-space: nowrap;
                margin-left: 10px;
                text-align: center;
              "
            >
            Trust %${Math.floor(properties.point * 100)}
            </p>
              </div>
          </div>
        `;
                    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                    }
                    popup.setLngLat(coordinates).setHTML(html).addTo(map);
                };
                const clickHandler = (id, id2, image) => {
                    fetchHandler(
                        `${process.env.REACT_APP_API_GEOJSON_URL}${Config.api.pointAPI}`
                    ).then((props) => {
                        let filteredArray = JSON.parse(
                            JSON.stringify(props.features)
                        ).filter((point) => point.properties.img_id === id);
                        setSelectedImage(image);
                        if (!addedImage) {
                            map.loadImage(
                                require("../assets/img/marker.png"),
                                (error, image) => {
                                    if (error) throw error;
                                    map.addImage("marker-point", image);
                                    addedImage = true;
                                }
                            );
                        }
                        dispatch({type: OBJECT_DETAIL_OPEN, payload: true});
                        dispatch({type: DRONE_DETAIL_OPEN, payload: false});
                        if (map.getLayer("pin-related")) {
                            map.removeLayer("pin-related");
                            map.addLayer({
                                id: "pin-related",
                                type: "symbol",
                                source: "layer-point",
                                filter: ["==", "img_id", id],
                                layout: {
                                    "icon-allow-overlap": !0,
                                    "icon-image": "marker-point",
                                },
                            });
                        } else {
                            map.addLayer({
                                id: "pin-related",
                                type: "symbol",
                                source: "layer-point",
                                filter: ["==", "img_id", id],
                                layout: {
                                    "icon-allow-overlap": !0,
                                    "icon-image": "marker-point",
                                },
                            });
                        }
                    });
                };
                newFeatures.forEach((symbol) => {
                    map.setLayoutProperty(symbol.id, "visibility", "none");
                    map.on("mouseenter", symbol.id, (e) => {
                        hoverHandler(
                            e,
                            e.features[0],
                            symbol.id,
                            "https://cdn.mapilio.com/api/extract/?src=H:/ENERJISA/ladybug_19027046_20201209_112023-000000/14.jpeg&xmax=4890&xmin=4664&ymax=2270&ymin=1488&size=1080"
                        );
                    });
                    map.on("mouseleave", symbol.id, () => {
                        popup.remove();
                    });
                    map.on("click", symbol.id, (e) => {
                        clickHandler(
                            e.features[0].properties.img_id,
                            symbol.id,
                            "https://cdn.mapilio.com/api/extract/?src=H:/ENERJISA/ladybug_19027046_20201209_112023-000000/14.jpeg&xmax=4890&xmin=4664&ymax=2270&ymin=1488&size=1080"
                        );

                        e.features[0].properties["coordinates"] = e.lngLat;
                        setSelectedDetail(e.features[0].properties);
                    });
                });
            }
        });
    };


    const PreloadImage = (imgSrc) => {
        var objImagePreloader = new Image();
        objImagePreloader.src = imgSrc;
        if (objImagePreloader.complete) {
            objImagePreloader.onload = function () {
            };
        } else {
            objImagePreloader.onload = function () {
                if (document.getElementById("lds") === null) return;
                document.getElementById("lds").setAttribute("style", "display:none");
                document.getElementById("image").setAttribute("style", "display:block");
                objImagePreloader.onload = function () {
                };
            };
        }
    };

    const clickDrone = (map) => {
        map.on("click", "layer-drone-point", (e) => {
            dispatch({type: 'CONTROL_PANEL_OPEN', payload: false});
            let imgArray = [];
            e.features.forEach(d => imgArray.push(d.properties.url))
            setSelectedDetail(e.features[0].properties)
            setSelectedImageDrone(imgArray);
            dispatch({type: DRONE_DETAIL_OPEN, payload: true});
            dispatch({type: OBJECT_DETAIL_OPEN, payload: false});
        });
    };

    const clickObject = (map) => {
        const hoverHandler = (e, features, image) => {
            let properties = features.properties;
            let keys = Object.keys(properties);
            keys = keys.filter((val) => !Config.excludedKeys.includes(val));
            const coordinates = features.geometry.coordinates.slice();
            const html = `
      <div style="border-radius:8px; padding:0; width: 10px">
         <img 
            id="image" 
            src=${image} 
            onload="${PreloadImage(image)}" 
            style="object-fit:cover; object-position:center; display:none;" width="98px" height="170px;"  
          />
          <div style="width: 170px">
          <p style="height: min-content; font-size: 16px; font-weight: 600; margin-top: 10px; margin-bottom: 5px; margin-left:15px; text-transform: capitalize; white-space: nowrap">
            ${Types.filter(type => type.className === properties.class_code)[0]?.fullName}
          </p>
          <span style="margin-left:15px; font-size: 14px; color: #C0C3C8; white-space: nowrap;"> 
            ${parseFloat(coordinates[1]).toFixed(5)} , ${parseFloat(coordinates[0]).toFixed(5)}
          </span>
          <p
          style="
            box-sizing:border-box;
            padding: 2.5px 10px;
            background-color: #75B505;
            border-radius: 13px;
            font-size: 12px;
            color: #FFF;
            white-space: nowrap;
            text-align: center;
            font-weight:400;
            width:90px;
            margin:12px 15px 0;
          "
        >
        Güven %${Math.floor(properties.confidence * 100)}
        </p>
          </div>
      </div>
    `;
            // while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            //   coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            // }
            popup.setLngLat(coordinates).setHTML(html).addTo(map);
        };
        const clickHandler = (id, image) => {
            setSelectedImage(image);
            if (!addedImage) {
                map.loadImage(require("../assets/img/marker.png"), (error, image) => {
                    if (error) throw error;
                    map.addImage("marker-point", image);
                    addedImage = true;
                });
            }
            dispatch({type: OBJECT_DETAIL_OPEN, payload: true});
            dispatch({type: DRONE_DETAIL_OPEN, payload: false});
            if (map.getLayer("pin-related")) {
                map.removeLayer("pin-related");
                map.addLayer({
                    id: "pin-related",
                    type: "symbol",
                    source: "layer-point",
                    filter: ["==", "img_code", id],
                    layout: {
                        "icon-allow-overlap": !0,
                        "icon-image": "marker-point",
                    },
                });
            } else {
                map.addLayer({
                    id: "pin-related",
                    type: "symbol",
                    source: "layer-point",
                    filter: ["==", "img_code", id],
                    layout: {
                        "icon-allow-overlap": !0,
                        "icon-image": "marker-point",
                    },
                });
            }
        };
        map.on("mouseenter", "nqfgcw4hju-objects-v5", (e) => {
            const {xmin, ymin, xmax, ymax} = bboxScale(e);
            hoverHandler(
                e,
                e.features[0],
                `https://cdn.mapilio.com/v2/im/extract/${e.features[0].properties.img_code}/${e.features[0].properties.filename}/${xmax}/${ymax}/${xmin}/${ymin}/240`
            );
        });
        map.on("mouseleave", "nqfgcw4hju-objects-v5", (e) => {
            popup.remove();
        });
        const symbolLayers = Types.map(type => `objects-${type.className}`)
        map.on("click", symbolLayers, (e) => {
            const {xmin, ymin, xmax, ymax} = bboxScale(e);
            dispatch({type: 'CONTROL_PANEL_OPEN', payload: false});

            clickHandler(
                e.features[0].properties.img_code,
                `https://cdn.mapilio.com/v2/im/extract/${e.features[0].properties.img_code}/${e.features[0].properties.filename}/${xmax}/${ymax}/${xmin}/${ymin}/480`
            );
            e.features[0].properties["coordinates"] = e.lngLat;
            setSelectedDetail(e.features[0].properties);
        });
    };

    const changeLayer = () => {
        setSatallite((prevState) => !prevState);
        if (appMap?.current) {
            if (isSatallite) {
                appMap.current.setLayoutProperty(
                    "mapbox-satellite",
                    "visibility",
                    "none"
                );
            } else {
                appMap.current.setLayoutProperty(
                    "mapbox-satellite",
                    "visibility",
                    "visible"
                );
            }
        }
    };

    return (
        <div>
            {Config.isWelcomeModal && isInfoShown && (
                <Info setClose={setIsInfoShown}/>
            )}
            <ToastMessage/>
            <MapHeader isSatallite={isSatallite}/>
            {useMemo(() => {
                return (
                    <>
                        <MapTraffic/>
                        <Mapbox
                            style={`${process.env.REACT_APP_API_MAP_URL}${urlToken}`}
                            containerStyle={{
                                height: "100vh",
                                width: "100vw",
                            }}
                            onStyleLoad={(map) => {
                                dispatch({type: MAP_CURRENT, payload: map});
                                appMap.current = map;
                                setMapReady(true);
                                map.setPaintProperty('nqfgcw4hju-objects-v5', 'icon-halo-width', 10)
                                map.setPaintProperty('nqfgcw4hju-objects-v5', 'icon-halo-color', 'red')
                                map.addControl(new mapboxgl.NavigationControl());
                                map.addControl(new MapboxTraffic());
                                if (!Config.componentToggles.studioDatas) {
                                    // addLines(map);
                                    addSymbols(map);
                                }
                                if (Config.isDroneProject) {
                                    clickDrone(map);
                                }
                                clickObject(map);

                            }}
                            center={center}
                            zoom={[8]}
                        >
                            <ZoomControl/>
                        </Mapbox>
                    </>
                );
            }, [])}
            {objectDetailPopup && (
                <ObjectDetail
                    selectedImage={selectedImage}
                    selectedDetail={selectedDetail}
                />
            )}
            {droneDetailPopup && (
                <ObjectDetailDrone
                    selectedImage={selectedImageDrone}
                    selectedDetail={selectedDetail}
                />
            )}
            {Config.isSatellite && (
                <div className={"map-layer"} onClick={changeLayer}>
                    <img
                        src={isSatallite ? StreetMap : MapImage}
                        alt={"Map"}
                    />
                </div>
            )}
        </div>
    );
};

export default memo(Map);
