import React from "react";
import {Modal} from "@mui/material";
import ReportModalHeader from "./ReportModalHeader";
import ReportModalContent from "./ReportModalContent";
import {useSelector} from "react-redux";
import ReportDetail from "../../ReportDetailComponent";

const ReportButton = () => {
	const {reportModal} = useSelector(state => state.reportReducer);

	return (
		<div>
			<Modal
				open={reportModal}
				className={"report-modal"}
			>
				<div className={"report-modal-content"}>
					<div className="report-modal-container">
						<ReportModalHeader/>
						<ReportModalContent/>
					</div>
				</div>
			</Modal>
			<ReportDetail/>
		</div>
	);
};

export default ReportButton;
