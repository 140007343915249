import React from "react";
import { useDispatch } from "react-redux";
import ClearIcon from "../../assets/svg/clear.svg";
import { OBJECT_DETAIL_OPEN } from "../../store/actionsName";

const Types = require(`./../../../config/${process.env.REACT_APP_CONFIG}`).Types;
const Config = require(`./../../../config/${process.env.REACT_APP_CONFIG}`).default;

const ObjectDetail = ({selectedImage, selectedDetail}) => {

	const dispatch = useDispatch();

	const getName = () => {
		const code = Config.componentToggles.studioDatas ? selectedDetail.class_code : selectedDetail.class_code
		return Types.filter(type => type.className === code)[0]?.fullName
	}

	const getIcon = () => {
		const code = Config.componentToggles.studioDatas ? selectedDetail.class_code : selectedDetail.class_code
		return Types.filter(type => type.className === code)[0]?.iconName.default
	}

	return (
		<div className={"detail-container"}>
			<div
				style={{
					padding: "0 15px",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
        <span
					style={{
						color: "#C0C3C8",
						marginTop: "0 !important",
						padding: "13px 0px",
					}}
				>
          Obje Detayı
        </span>
				<img
					src={ClearIcon}
					alt={"Close button"}
					style={{cursor: "pointer"}}
					onClick={()=>dispatch({ type: OBJECT_DETAIL_OPEN, payload: false })}
				/>
			</div>
			<div style={{width: "100%", height: "410px"}}>
				<img
					src={selectedImage}
					alt={"Object_picture"}
					style={{
						width: "100%",
						height: "100%",
						objectFit: "cover",
						objectPosition: "center",
					}}
				/>
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					padding: "0 5px",
				}}
			>
				<div style={{display: "flex", flexDirection: "column", position: "relative", padding: "10px", flex: 1}}>
					<div style={{ display: "flex", alignItems: "center" }}>
						<img src={getIcon()} alt={"Object type"} style={{height: "30px",}}/>
						<span style={{textTransform: "capitalize"}}>
              {getName()}
            </span>
					</div>
					<div style={{
						display: "inline-flex",
						flexDirection: "column",
						color: "#3D3D3D",
						fontSize: "14px",
						margin: ".5rem 0",
					}}>
						<span style={{color: "#C0C3C8"}}><small>ID: {selectedDetail.id}</small></span>
						{
							selectedDetail.class_code !== "inst-tree" && (
								<span style={{ display: "flex", flexWrap: 'wrap'}}>
									<span style={{flex: '50%'}}>Levha Yok.</span>
									<span style={{flex: '50%'}}>Eğim Yok.</span>
									<span style={{flex: '50%'}}>Sorun: Tespit edilemedi.</span>
								</span>
							)
						}
					</div>
					<span
						style={{
							fontSize: "14px",
							color: "#C0C3C8",
							marginTop: "4px",
							whiteSpace: "nowrap",
							marginBottom: "10px",
						}}
					>
            {parseFloat(selectedDetail?.coordinates?.lat).toFixed(5)},{" "}
						{parseFloat(selectedDetail?.coordinates?.lng).toFixed(5)}
          </span>
					<span
						style={{
							padding: "5px 10px",
							backgroundColor: "#75B505",
							borderRadius: "16px",
							fontSize: "12px",
							color: "#fff",
							height: "min-content",
							whiteSpace: "nowrap",
							position: "absolute",
							top: "10px",
							right: "15px",
						}}
					>
          Güven %
						{Config.componentToggles.studioDatas
							? Math.floor(selectedDetail.confidence * 100)
							: Math.floor(selectedDetail.point * 100)}
        </span>
				</div>
			</div>
		</div>
	);
};

export default ObjectDetail;
