import React from "react";
import LeftArrow from "../../../../assets/svg/left-arrow.svg";
import {useDispatch} from "react-redux";

const ReportModalHeader = () => {
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch({type: "REPORT_MODAL", payload: false})
	};


	return (
		<div className={"report-modal-header"}>
			<button className={"back-button"} onClick={() => handleClose()}>
				<img src={LeftArrow} alt={"Back Icon"}/> Geri
			</button>
		</div>
	);
};

export default ReportModalHeader;
