export const MAP_CURRENT = "MAP_CURRENT";
export const UPDATE_POINT_TYPE = "UPDATE_POINT_TYPE";
export const FILTERED_POINTS = "FILTERED_POINTS";
export const ROADS = "ROADS";
export const REPORTS = "REPORTS";
export const SET_MESSAGE = "SET_MESSAGE";
export const CONTROL_PANEL_DATA = "CONTROL_PANEL_DATA";
export const REPORT_MODAL = "REPORT_MODAL";
export const REPORT_DETAIL_MODAL = "REPORT_DETAIL_MODAL";
export const FILTERED_CLASS = "FILTERED_CLASS";
export const REPORT_DETAIL_DATA = "REPORT_DETAIL_DATA";
export const FILTERED_REPORT_DATA = "FILTERED_REPORT_DATA";
export const OBJECT_DETAIL_OPEN = "OBJECT_DETAIL_OPEN";
export const DRONE_DETAIL_OPEN = "DRONE_DETAIL_OPEN";
export const FILTERED_POWER_LINES = "FILTERED_POWER_LINES";
export const CONTROL_PANEL_OPEN = "CONTROL_PANEL_OPEN";
