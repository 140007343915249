import { store } from "../store/store";

export const fetchHandler = (url) => {
  return fetch(url).then((response) => response.json());
};

export const maxCharacterHandler = (text, maxLength) => {
  if (text.length > maxLength) text = text.substring(0, maxLength) + "...";
  return text;
};

export const flyHandler = (coordinates, zoom = 10) => {
  const mapCurrent = store.getState().mapReducer.currentMap;
  mapCurrent.setZoom(zoom);
  mapCurrent.flyTo({ center: coordinates, essential: true });
};