import React, {useEffect, useState} from "react";
import 'react-multi-carousel/lib/styles.css';
import {Chart} from "react-google-charts";
import {useDispatch, useSelector} from "react-redux";
import {Grid, useMediaQuery} from "@mui/material";
import {Checkbox} from "../../PartialsComponent";

const Types = require(`./../../../../../config/${process.env.REACT_APP_CONFIG}`).Types;

const ReportModalChart = () => {
	const {reports} = useSelector(state => state.reportReducer);
	const [chartData, setChartData] = useState([]);
	const [checked, setChecked] = useState([]);
	const dispatch = useDispatch();
	const getName = (slug) => Types.filter(type => type.className === slug)[0]?.fullName
	const phoneWidth=useMediaQuery(`(max-width:768px)`)


	const setChartDataState = () => {
		let newChart = [];
		newChart.push(["Kategori", "Sayı"]);
		if (reports?.data) {
			reports.data.map((report) => {
				if (checked.length === 0) {
					newChart.push([getName(report.class_code), report.count])
				} else {
					checked.find(type => type === report.class_code) && newChart.push([getName(report.class_code), report.count]);
				}
			})
			dispatch(({type:"FILTERED_REPORT_DATA",payload:newChart}))
		}
		return newChart;
	}

	useEffect(() => {
		setChartData(setChartDataState());
		dispatch({type: "FILTERED_CLASS", payload: checked});
	}, [reports, checked]);

	const isChecked = (slug) => {
		return Boolean(checked.find(type => type === slug));
	}

	const handleChecked = (e) => {
		if (isChecked(e.target.value)) {
			setChecked(checked.filter(type => type !== e.target.value));
		} else {
			setChecked([...checked, e.target.value]);
		}
	}

	const checkReset = () => {
		setChecked([])
	}

	return (
		<div className={"report-modal-content-detail"}>
			<div className="report-modal-content-detail-filter">
				<div className="report-modal-content-detail-filter-title">
					Filtrele
				</div>
				<div className="report-modal-content-detail-filter-description">
					Tespit edilen obje raporlama
				</div>
				<Grid container className="report-modal-content-detail-filter-inputs">
					{Types.map((type, index) => {
						return (
							<Grid item xs={12} lg={6} key={index}>
								<Checkbox
									key={type.className}
									checked={isChecked(type.className)}
									onChange={(e) => handleChecked(e)}
									value={type.className}
									text={getName(type.className)}
								/>
							</Grid>
						)
					})}

					{
						checked.length > 0 && <Grid item xs={12}>
							<p className={"check-reset"} onClick={checkReset}>Seçimleri Temizle</p>
						</Grid>
					}

				</Grid>
			</div>
			<div className={"report-modal-content-detail-chart"}>
				{
					chartData.length > 1 ? <Chart
						chartType="PieChart"
						loader={<div>Grafik Yükleniyor</div>}
						data={chartData}
						options={{
							is3D: true,
							legend:{
								position:phoneWidth && 'bottom',
								alignment:'center',
							}
						}}
						width="100%"
						height="350px"
					/> : <Chart
						chartType="PieChart"
						loader={<div>Grafik Yükleniyor</div>}
						data={[['',''],['0%',1]]}
						options={{
							is3D:true,
							legend:'none',
							colors:['#EBEBEB'],
							title:'No Data',
							pieSliceText:'label',
							pieSliceTextStyle:{color:'black'},
							chartArea: {backgroundColor: '#f1f7f9'}
						}}
						width="100%"
						height="350px"
					/>
				}

			</div>
		</div>
	);
};

export default ReportModalChart;
