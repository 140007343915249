import "./enerjisaComponents/css/App.scss";
import "./enerjisaComponents/css/index.scss";
import RoutePages from "./enerjisaComponents/Routes";
import { Provider } from "react-redux";
import { store } from "./enerjisaComponents/store/store";
import { useEffect } from "react";

const Config = require(`./config/${process.env.REACT_APP_CONFIG}`).default;

function App() {
  useEffect(() => {
    document.title = Config.title;
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href =
      "https://iconape.com/wp-content/png_logo_vector/enerjisa-logo.png";
  }, []);

  return (
    <Provider store={store}>
      <RoutePages />
    </Provider>
  );
}

export default App;
