import React from "react";

const Checkbox = ({checked, onChange, value, text, icon = null,colorName}) => {
	return (
		<label className="container-filter">
			<input type="checkbox" value={value} checked={checked} onChange={onChange}/>
			<span
				className="checkmark-filter"
				style={{backgroundColor: checked ? "#fbc233" : "transparent"}}
			></span>
			{
				icon && <img
					src={icon.default}
					alt={"Symbol"}
				/>
			}
			{
				colorName && <div className="powerline-icon" style={{backgroundColor:colorName}}>

				</div>
			}
			<span className={"label-text-filter-list"}>{text}</span>
		</label>
	)
}

export default Checkbox;
