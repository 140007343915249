import {SET_MESSAGE} from "../actionsName";

const INITIAL_STATE = {
    message:{
        status:false,
        message:'',
        type:null,
    }
};

const globalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_MESSAGE:
            return { ...state, message: action.payload};
        default:
            return state;
    }
};

export default globalReducer;
