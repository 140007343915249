import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CarIcon from "../../assets/svg/Car.svg";
import WhiteCarIcon from "../../assets/svg/WhiteCar.svg";
import DroneIcon from "../../assets/svg/Drone.svg";
import WhiteDroneIcon from "../../assets/svg/WhiteDrone.svg";
import { UPDATE_POINT_TYPE } from "../../store/actionsName";

const SwitchControl = () => {
  const [checked, setChecked] = useState("car");
  const dispatch = useDispatch();

  useEffect(() => {
      dispatch({ type: UPDATE_POINT_TYPE, payload: (checked === "car") });
  }, [checked]);

  return (
    <div className="switch-container">
      <input
        type="radio"
        name="point"
        id="car"
        defaultChecked={checked === "car"}
        hidden
        onClick={(e) => setChecked(e.target.id)}
      />
      <label htmlFor="car">
        <img src={checked === "car" ? WhiteCarIcon : CarIcon} alt={"car"} id={"car-icon"} />
      </label>
      <input
        type="radio"
        name="point"
        id="drone"
        hidden
        defaultChecked={checked === "drone"}
        onClick={(e) => setChecked(e.target.id)}
      />
      <label htmlFor="drone">
        <img
          src={checked === "drone" ? WhiteDroneIcon : DroneIcon}
          alt={"drone"}
          id={'drone-icon'}
        />
      </label>
    </div>
  );
};

export default SwitchControl;
