import React, {useEffect, useState} from "react";
import {fetchHandler} from "../../../helper/helper";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {Message} from "../../../helper/alert";

const Types = require(`./../../../../config/${process.env.REACT_APP_CONFIG}`).Types;

const ReportDetailTable = ({handleClose}) => {
	const [reportDetail, setReportDetail] = useState([]);
	const [message,setMessage]=useState('')
	const getName = (slug) => Types.filter(type => type.className === slug)[0]?.fullName
	const {filteredClass} = useSelector(state => state.reportReducer);
	const dispatch = useDispatch();

	const getReportDetail = () => {
		let params = '';
		if (filteredClass.length > 0) {
			params = '&class_code[]=' + filteredClass.join("&class_code[]=")
		}
		fetchHandler(
			`${process.env.REACT_APP_API_MAPILIO}/api/reports/features?token=${process.env.REACT_APP_API_MAPILIO_TOKEN}&organization_key=${process.env.REACT_APP_ORG_KEY}&project_key=${process.env.REACT_APP_PROJECT_KEY}&paginate=false${params}`
		).then(({response}) => {
			setReportDetail(response);
		});
	}

	useEffect(() => {
		const exportData = JSON.parse(JSON.stringify(reportDetail));
		exportData.map(item => {
			item.name = getName(item.class_code);
			item.plate = JSON.parse(item.features)?.plate ? "yes" : "no";
			item.area = parseInt(item.area).toFixed(2);
			item.confidence = Math.floor(item.confidence * 100) + '%';
			item.dangerous_tree = '';
			item.date = moment(item.created_at).format('DD.MM.YYYY HH:mm:ss');
			delete item.created_at;
			delete item.features;
			return item;
		})
		dispatch({type: 'REPORT_DETAIL_DATA', payload: exportData});
		if (reportDetail.length){
			Message.success('Rapor başarıyla oluşturuldu!')
		}
	}, [reportDetail])

	const isPlate = (features) => {
		if (features) {
			return JSON.parse(features).plate
		}
	}

	useEffect(() => {
		getReportDetail();
		return () => {
			setReportDetail([]);
		}
	}, []);

	return (
		<>{!!reportDetail.length && (
				<table>
				<thead>
				<tr>
					<th>ID</th>
					<th>Adı</th>
					<th>İhlal <span>(Var/Yok)</span> </th>
					<th>Levha<span>(Var/Yok)</span></th>
					<th>En-Boy<span>(&#x33A1;)</span></th>
					<th>Güven Puanı</th>
				</tr>
				</thead>
				<tbody>

				{reportDetail.map((report) => {
					return (
						<tr key={report.id}>
							<td>#{report.id}</td>
							<td>{getName(report.class_code)}</td>
							<td>Yok</td>
							<td>{isPlate(report.features) ? "Var" : "Yok"}</td>
							<td>{report.class_code === 'inst-tree' ? Math.round(report.area) : '-'}</td>
							<td>Güven {Math.floor(report.confidence * 100)}%</td>
						</tr>
					)
				})
				}
				</tbody>
				</table>
			)}
		</>
	)
}

export default ReportDetailTable;
