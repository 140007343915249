import React, { useEffect, useState } from "react";
import SearchIcon from "../../assets/svg/search.svg";
import {
  fetchHandler,
  flyHandler,
  maxCharacterHandler,
} from "../../helper/helper";

const LocationSearch = () => {
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [textAPI, setTextAPI] = useState("");
  const [results, setResults] = useState("");

  useEffect(() => {
    // const ourRequest = axios.CancelToken.source();

    if (textAPI.length !== 0) {
      setOpen(true);
      fetchHandler(`${process.env.REACT_APP_API_SEARCH_URL}/api/?q=${textAPI}`)
        .then((res) => {
          setResults(res.features);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(true);
        });
    } else {
      setOpen(false);
      setResults([]);
    }

    return () => {
      //   ourRequest.cancel();
    };
  }, [textAPI]);

  return (
    <div className="search-box">
      <input
        className="search-text"
        type="text"
        placeholder={"Lokasyon Ara..."}
        onChange={(e) => {
          setSearchText(e.target.value);
          setLoading(true);
          setTimeout(() => {
            setTextAPI(e.target.value);
          }, 1000);
        }}
        onBlur={() => setOpen(false)}
        value={searchText}
      />
      <a href="#" className="search-btn">
        <img src={SearchIcon} alt={"Search Icon"} />
      </a>
      <div className={open ? "locations-container" : "locations-container-close"}>
        <ul className={"locations-list"}>
          {!loading ? (
            !loading && results.length === 0 ? (
              textAPI.length === 0 ? (
                <p>Please type location</p>
              ) : (
                <p>Location not found</p>
              )
            ) : (
              results.map((result) => {
                return (
                  <li
                    className={"locations-list-item"}
                    onClick={() => {
                      flyHandler(result.geometry.coordinates, 15);
                    }}
                  >
                    <p>{`${maxCharacterHandler(result.properties.name, 45)}${
                      result.properties.city
                        ? "," + " " + result.properties.city
                        : ""
                    }`}</p>
                    <span>{result.properties.country}</span>
                  </li>
                );
              })
            )
          ) : loading && textAPI.length !== 0 ? (
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : (
            <p>Please type location</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default LocationSearch;
