import {CONTROL_PANEL_DATA,CONTROL_PANEL_OPEN} from "../../actionsName";

const INITIAL_STATE = {
    controlPanelData: [],
    controlPanelOpen: false,
};

const controlReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONTROL_PANEL_DATA:
            return { ...state, controlPanelData: action.payload};
        case CONTROL_PANEL_OPEN:
            return { ...state, controlPanelOpen: action.payload};
        default:
            return state;
    }
};

export default controlReducer;
