import React, {createRef, useState} from 'react';
import {Modal} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {ReportLogo,Excel,LeftArrow,LoadingIcon,VisiosoftIcon} from "../../../assets/svg/index"
import ReportDetailTable from "./ReportDetailTable";
import Moment from 'react-moment';
import {CSVLink} from "react-csv";
import ReportTotalTable from "./ReportTotalTable";
import moment from 'moment';

const Types = require(`./../../../../config/${process.env.REACT_APP_CONFIG}`).Types;

const ReportDetail = () => {
	const dispatch = useDispatch();
	const [isVisibleRightMenu,setIsVisibleRightMenu]=useState(true);
	const {reportDetailModal, reportDetailData} = useSelector(state => state.reportReducer);
	const getName = (slug) => Types.filter(type => type.className === slug)[0]?.fullName
	const handleClose = () => dispatch({type: "REPORT_DETAIL_MODAL", payload: false});

	const getFileName = (mime) => moment().format('DD_MM_YYYY_HH_mm_ss') + '.' + mime;

	const ref = createRef();

	const handleScroll=(e)=>{
		if ((e.target.scrollTop+e.target.offsetHeight+200)>e.target.scrollHeight){
			setIsVisibleRightMenu(false)
		}else{
			setIsVisibleRightMenu(true)
		}
	}

	return (
		<Modal
			open={reportDetailModal}
			onClose={handleClose}
			className={"report-detail-modal"}
		>
			<div className={"report-detail-modal-container"} onScroll={handleScroll}>
				<div className="reportDetailHeader">
					{!!reportDetailData.length && <button className="back-button" onClick={handleClose}>
						<img src={LeftArrow} alt="left-arrow"/>
						{" Geri"}
					</button>}
				</div>
				<div className="report-detail-modal-wrapper" ref={ref}>
					<div className={"report-detail-modal-header"}>
						<div className="logo">
							<img src={ReportLogo} alt="Enerjisa Rapor Ekranı Logo"/>
						</div>
						{reportDetailData.length>0 && <div className="report-detail-modal-date">
							<Moment format="DD.MM.YY">{new Date()}</Moment>
							<Moment format="HH:mm">{new Date()}</Moment>
						</div>}
					</div>
					<div className={"report-detail-modal-content"}>

						{!reportDetailData.length && (
							<div className={'report-loading'}>
							<div className={"report-created"}>Rapor Oluşturuluyor</div>
							<img src={LoadingIcon} alt="loading"/>
							</div>
						)}
						{reportDetailData.length>0 && (<><div className="report-detail-modal-content-title">
							<span>Rapor</span>
						</div>
							<div className="report-detail-modal-content-subtitle">
							<span>Tespit edilen ve işlenen verilerin raporu</span>
							</div></>)}
						<ReportDetailTable handleClose={handleClose}/>
						{reportDetailData.length>0 && <ReportTotalTable/>}
						{reportDetailData.length>0 && <div className="report-detail-modal-content-footer">
							<div className="report-detail-modal-content-footer-info">
								<div className={"report-detail-modal-content-footer-info-left"}>
									<span className={"light"}>© Visiosoft | 2022</span>
								</div>
								<div className="report-detail-modal-content-footer-info-right">
									<span>Görüntü işleme ve Yapay Zeka teknolojileri</span>
									<img src={VisiosoftIcon} alt="visiosoftlogo"/>
								</div>
							</div>
						</div>}
					</div>
				</div>
				{reportDetailData.length>0 && <div className="report-detail-modal-content-actions">
					<div className="report-detail-modal-content-actions-export-excel">
						{
							reportDetailData.length > 0 && (
								<CSVLink className={"btn success"} data={reportDetailData} filename={getFileName('csv')}>
									<img src={Excel} alt="excel export icon"/>
									Excel
								</CSVLink>
							)
						}
					</div>
				</div>}
				{reportDetailData.length>0 && <div className={`right-export-menu ${!isVisibleRightMenu && 'non-visible'}`}>
					<CSVLink className={'btn'} data={reportDetailData} filename={getFileName('csv')}>
						<img src={Excel} alt="excel export icon"/>
					</CSVLink>
				</div>}
			</div>
		</Modal>
	)
}

export default ReportDetail;
