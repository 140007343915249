import React from "react";
import {EnerjisaBlackLogo,EnerjisaWhiteLogo} from "../../assets/svg/index";
import Filter from "./Filter";
import LocationSearch from "./LocationSearch";
import SwitchControl from "./SwitchControl";
import ControlButton from "./ControlSystemComponent";
import ReportButton from "./ReportComponent";
const Config = require(`./../../../config/${process.env.REACT_APP_CONFIG}`).default;

const MapHeader = ({isSatallite}) => {
  return (
    <div className={"map-header"}>
      <div className={"navigation"}>
        <LocationSearch />
        <Filter />
          {Config.isControl && (
              <ControlButton />
          )}
          {Config.isReport && (
              <ReportButton />
          )}
      </div>
      <div className={"navigation-right"}>
          {Config.isDroneProject && (
              <SwitchControl />
          )}
        <img
          src={isSatallite ? EnerjisaWhiteLogo : EnerjisaBlackLogo}
          alt={"logo"}
          className={"map_logo"}
        />
      </div>
    </div>
  );
};

export default MapHeader;
