import React, {useEffect} from "react"
import {Info,Success} from "../../assets/svg/index"
import {useSelector} from "react-redux";
import { Message } from "../../helper/alert"

const ToastMessage =()=>{
    const { message } = useSelector((state) => state.globalReducer);

    useEffect(()=>{
        if (message.status){
            const timeout=setTimeout(()=>Message.close(),3000)
            return ()=>clearTimeout(timeout)
        }
    },[message])

    return (
        <>
            {message.status && <div className={'message-container'}>
                <div className="message-content">
                    <div
                        style={{backgroundColor: message.type === 'info' ? '#FBC233' : message.type === 'success' ? '#25D931' : ''}}>
                        <img src={message.type === 'info' ? Info : message.type === 'success' ? Success : ''}
                             alt="info"/>
                    </div>
                    <span>{message.message}</span>
                    <div className="duration"></div>
                </div>
            </div>}
        </>
    )
}
export default ToastMessage