import '../css/Login.scss'
import {useNavigate} from "react-router";
import React, {useEffect, useState} from "react";
import axios from 'axios';

const Login = () => {
    const [loginForm, setLoginForm] = useState({email: '', password: ''})
    const [showError, setShowError] = useState(false)
    const [isActiveButton, setIsActiveButton] = useState(true)

    const navigate = useNavigate()

    const handleLogin = (e => {
        e.preventDefault()
        setLoginForm({
            email: e.target.email.value,
            password: e.target.password.value,
        })

        let auth = ''

        axios.post(`${process.env.REACT_APP_API_MAPILIO}/api/login`, loginForm)
            .then(d => {
                auth = JSON.stringify(d.data)
                localStorage.setItem('auth', auth)
                navigate('/map')
            })
            .catch(err => {
                e.target.email.style.color = '#D90A0A'
                e.target.password.style.color = '#D90A0A'
                setShowError(true)
            })
    })

    const handleOnChange = (e) => {
        if (e.target.id === 'remember') {
            setLoginForm({
                ...loginForm,
                [e.target.id]: e.target.checked
            })
        }
        setLoginForm({
            ...loginForm,
            [e.target.id]: e.target.value
        })
    }

    useEffect(() => {
        if (loginForm.email.length > 0 && loginForm.password.length > 0) {
            setIsActiveButton(false)
        } else {
            setIsActiveButton(true)
        }
    }, [loginForm])

    return (<div className={'auth'}>
        <h1>Giriş</h1>
        <p>Hesabınıza erişmek için bilgilerinizi giriniz</p>
        {showError && <div className="alert alert-danger">
            Girdiğiniz e-posta veya şifre hatalı!
            <span className={"close-icon"} onClick={() => setShowError(false)}>
            &times;
          </span>
        </div>}
        <form onSubmit={handleLogin}>
            <label className={'form-input'}>
                <span>E-Posta Adresi</span>
                <input value={loginForm.email} onChange={handleOnChange} id={'email'} type="text"/>
            </label>
            <span className={'forgot'} onClick={() => navigate('/reset-password')}>Şifremi unuttum?</span>
            <label className={'form-input'}>
                <span>
                    <span>Şifre</span>
                </span>
                <input value={loginForm.password} onChange={handleOnChange} id={'password'} type="password"/>
            </label>
            <label className="checkbox">
                <input id={'remember'} type="checkbox"/>
                <span>Beni hatırla</span>
            </label>
            <button disabled={isActiveButton} id={'button'} type={"submit"}
                    style={!isActiveButton ? {backgroundColor: '#FBC233',color:'#000'}:null}
            >Giriş yap
            </button>
            <p>
                Üye değil misiniz?
                <span onClick={() => navigate('/register')}>Kayıt ol</span>
            </p>
        </form>
    </div>)
}
export default Login