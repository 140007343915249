import {
  FILTERED_CLASS,
  FILTERED_REPORT_DATA,
  REPORT_DETAIL_DATA,
  REPORT_DETAIL_MODAL,
  REPORT_MODAL,
  REPORTS,
  ROADS
} from "../../actionsName";

const INITIAL_STATE = {
  roads: '0km',
  reports: [],
  reportModal: false,
  reportDetailModal: false,
  filteredClass: [],
  filteredReports:[],
  reportDetailData: []
};

const reportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ROADS:
      return { ...state, roads: action.payload };
    case REPORTS:
      return { ...state, reports: action.payload };
    case REPORT_MODAL:
      return { ...state, reportModal: action.payload };
    case REPORT_DETAIL_MODAL:
      return { ...state, reportDetailModal: action.payload };
    case FILTERED_CLASS:
      return { ...state, filteredClass: action.payload };
    case FILTERED_REPORT_DATA:
      return {...state,filteredReports: action.payload};
    case REPORT_DETAIL_DATA:
      return { ...state, reportDetailData: action.payload };
    default:
      return state;
  }
};

export default  reportReducer;
