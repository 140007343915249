import { combineReducers } from "redux";
import { mapReducer, reportReducer ,controlReducer} from "./reducers";
import globalReducer from "./reducers/globalReducer"

export default combineReducers({
  mapReducer,
  reportReducer,
  controlReducer,
  globalReducer
});
