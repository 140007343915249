import {Navigate} from "react-router";
const Config = require(`./../config/${process.env.REACT_APP_CONFIG}`).default;


const PrivateRoute=({children})=>{
    if (Config.isLogin)
    {
        if (localStorage.getItem('auth')){
            return <>{children}</>
        }
        return <Navigate to={'/login'}/>
    }
    return <>{children}</>

}
export default PrivateRoute