import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import reducer from "./combineReducers";

const logger = createLogger();

const middleware = [thunk, logger];

export const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
);
