import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import * as ROUTES from "./RoutesConfig";
import Login from "./auth/Login";
import Map from "./Map/Map";
import AuthLayout from "./auth/AuthLayout";
import PrivateRoute from "./PrivateRoute";
const Config = require(`./../config/${process.env.REACT_APP_CONFIG}`).default;


const RoutePages = () => {
    return (
        <BrowserRouter>
            <Routes>
                {Config.isLogin && (
                    <Route exact path={ROUTES.LOGIN} element={
                        <AuthLayout>
                            <Login/>
                        </AuthLayout>
                    }/>
                )}
                <Route exact path={ROUTES.MAP} element={
                    <PrivateRoute>
                        <Map/>
                    </PrivateRoute>
                }/>
                <Route path="*" element={
                    <PrivateRoute>
                        <Map/>
                    </PrivateRoute>
                }/>
            </Routes>
        </BrowserRouter>
    );
};

export default RoutePages;
