import React from "react";
import ReportModal from "./ReportModal";
import {useDispatch} from "react-redux";
import Report from "../../../assets/svg/report.svg";

const ReportButton = () => {
	const dispatch = useDispatch();

	const handleOpen=()=>{
		dispatch({type: "REPORT_MODAL", payload: true});
		dispatch({type:"DRONE_DETAIL_OPEN",payload:false});
		dispatch({type:"OBJECT_DETAIL_OPEN",payload:false});
	}

	return (
		<div className={'btn-report'}>
			<div className={"btn primary"} onClick={handleOpen}>
				<img src={Report} alt="Report icon"/>
			</div>
			<ReportModal />
		</div>
	);
};

export default ReportButton;
