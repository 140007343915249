import React from "react";
import ReactDOM from "react-dom";
import "./css/styles.css";
const Theme = require(process.env.REACT_APP_LAYOUT).default;

ReactDOM.render(
  <React.StrictMode>
    <Theme />
  </React.StrictMode>,
  document.getElementById("root")
);
