import React from "react";
import {useSelector} from "react-redux";

const Types = require(`./../../../../config/${process.env.REACT_APP_CONFIG}`).Types;

const ReportTotalTable = () => {
	const {reports, filteredClass} = useSelector(state => state.reportReducer);

	const getName = (slug) => Types.filter(type => type.className === slug)[0]?.fullName

	const getTable = () => {
		return reports?.data?.map((report ,i) => {
			if (filteredClass.length === 0 || Boolean(filteredClass.find(type => type === report.class_code))) {
				return (
					<td key={i} style={{ display:"flex", justifyContent: "space-between" }}>
						<span style={{ fontWeight: "300" }}>{getName(report.class_code)}</span>
						<span style={{ fontWeight: "500" }}>{report.count}</span>
					</td>
				)
			}
		})
	}

	return (
		<div>
			<table>
				<tbody>
				<tr style={{ display: "grid", gridTemplateColumns: "auto auto auto auto" }}>
					<td rowSpan={10} style={{ gridRow: "1 / span 100", borderBottom: "none", fontWeight: "600"}}>TOPLAM:</td>
					{getTable()}
				</tr>
				</tbody>
			</table>
		</div>
	)
}

export default ReportTotalTable;
