import React, {useEffect, useState} from 'react';
import PavedRoad from '../../../../assets/svg/paved-road.svg';
import Export from '../../../../assets/svg/export.svg';
import {fetchHandler} from "../../../../helper/helper";
import ReportModalSlider from "./ReportModalSlider";
import ReportModalChart from "./ReportModalChart";
import {useDispatch, useSelector} from "react-redux";
import {Message} from "../../../../helper/alert";

const Types = require(`./../../../../../config/${process.env.REACT_APP_CONFIG}`).Types;

const ReportModalContent = () => {
	const dispatch = useDispatch();
	const {roads, filteredReports} = useSelector(state => state.reportReducer);
	const [message, setMessage] = useState('')
	const getName = (slug) => Types.filter(type => type.className === slug)[0]?.fullName

	const getRoads = () => {
		fetchHandler(`${process.env.REACT_APP_API_MAPILIO}/api/reports/roads?token=${process.env.REACT_APP_API_MAPILIO_TOKEN}&organization_key=${process.env.REACT_APP_ORG_KEY}&project_key=${process.env.REACT_APP_PROJECT_KEY}`).then(({response}) => {
			const roads = response.total_roads.toString() + response.length_type.toString()
			dispatch({type: 'ROADS', payload: roads});
		})
	}

	const getReports = () => {
		fetchHandler(`${process.env.REACT_APP_API_MAPILIO}/api/reports/class?token=${process.env.REACT_APP_API_MAPILIO_TOKEN}&organization_key=${process.env.REACT_APP_ORG_KEY}&project_key=${process.env.REACT_APP_PROJECT_KEY}`).then(({response}) => {
			dispatch({type: 'REPORTS', payload: response});
		})
	}

	useEffect(() => {
		getRoads();
		getReports();
	}, [])

	const openReportDetail = () => {
		if (filteredReports.length !== 1) {
			dispatch({type: 'REPORT_DETAIL_MODAL', payload: true});
		}else {
			Message.info('Rapor oluşturulacak veri bulunamadı!')
		}
	}


	const Header = () => {
		return (
			<div className={"report-modal-content-header"}>
				<div className={"report-modal-content-header-title"}>
					<p className={"title"}>Enerjisa Envanteri</p>
					<p className={"subtitle"}>Tespit edilen objeler ve Raporlama</p>
				</div>
				<div className={"report-modal-content-header-side"}>
					<div className={"report-modal-content-header-side-items"}>
						<div className={"road-info"}>
							<p className={"title"}>Toplam yol</p>
							<div className={"road-info-value"}>
								<img src={PavedRoad} alt={""}/>
								{roads}
							</div>
						</div>
						<div className={"export-btn"}>
							<p className={"title"} onClick={openReportDetail}>
								<img src={Export} alt={"Export button"}/>
								Rapor Oluştur
							</p>
						</div>
					</div>
				</div>
			</div>
		)
	};

	return (
		<div>
			<Header/>
			<ReportModalSlider/>
			<ReportModalChart/>
		</div>
	)
}

export default ReportModalContent;
