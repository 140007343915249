import {
    EnerjisaWhiteLogo,
    TomtomIcon,
    MapboxIcon,
    EsriIcon,
    HereIcon,
    GoogleMapsIcon,
    VisiosoftIcon,
    InfoContentImg,
    CloseIcon,
    InfoImg, PlayIcon
} from '../../assets/svg/index'
import {useState} from "react";


const Info =(props)=>{
    const [logoHeight,setLogoHeight]=useState(40)

    const handleScroll=e=>{
        const scrollHeight=40 - Math.floor(e.target.scrollTop/6)
        setLogoHeight(scrollHeight)
    }

    return(
        <div className={'info-container-backdrop'}>
            <div className="info-container">
                <div className="info-content">
                    <div className="content-header">
                        <div style={{padding:logoHeight > 0?logoHeight+'px 0 '+logoHeight/2+'px 0':0}} className="logo">
                            <img height={logoHeight>0 ?logoHeight : 0} src={EnerjisaWhiteLogo} alt="enerjisa"/>
                        </div>
                        <h1>Envanter Yönetim Sistemi</h1>
                        <h2>Tespit edilen objeler ve Raporlama</h2>
                        <img src={InfoImg} alt="info"/>
                    </div>
                    <div onScroll={handleScroll} className="content-main">
                        <h2>Nasıl Çalışır?</h2>
                        <p>
                            Envanter yönetim sistemi, kullanıcıların topladığı sokak görüntüleri üzerinden,
                            istenilen objeleri tespit eden, tespit edilen objeleri haritada konumlandıran,
                            istek dahilinde kurum/kişi özelinde panel oluşturma/kullanma ve verileri saklama imkanı sunan
                            bir platformdur. Platform istenilen bir çok kamera ve gps cihazı ile uyumlu çalışmaktadır.
                        </p>
                        <h2>Panel Kaydı Oluşturma </h2>
                        <p>
                            Veri ve görüntüler toplanmaya başlamadan önce, kullanıcı ‘Visiosoft’ platformuna kayıt olmalı.
                            Böylece kullanıcı kendi verilerini kendisine ayrılan panelden kontrol edebileceklerdir.
                            Kullanıcı verileri toplamaya başlamadan önce, harita üzerinden çekim yapmak istediği noktaları işaretleyerek,
                            proje oluşturabilir. Bu işlemlerin ardından oluşturulan proje kapsamında veri toplamaya başlanır.
                        </p>
                        <h2>Verilerin Toplanması</h2>
                        <p>
                            Görüntüler; Aksiyon kameraları, cep telefonu veya profesyonel panoramik kameralar(Ladybug vb.) ile toplanabilir.
                            Toplanan veriler sunucuya yüklenir. Yüklenen veriler herhangi bir teknik aksaklık olmaması adına kontrol edilir.
                            Çekim noktalarında herhangi bir anormal veri varsa veya çekim esnasında herhangi bir teknik aksaklık
                            sebebiyle ortaya anormaller çıktıysa, bu verilerin sunucuya yüklenmesi önlenir. Filtrelenen ve sunucuya
                            yüklenen veriler, daha sonra yapay zekada işlenmek üzere yapay zeka sunucusuna görüntü gönderilir.
                        </p>
                        <h2>Sunucuda Toplanan Verilerin İşlenmesi</h2>
                        <p>
                            Yapay zeka sunucusuna gelen görüntüler, eğitilmiş modelden geçmektedir. Eğitilen model elektrik
                            direklerinin; ağaç, demir veya beton olmasına göre veya gerilim seviyesine göre sınıflandırır.
                            Alçak Gerilim Ağaç Elektrik Direği, Orta Gerilim Demir Elektrik Direği, Yüksek Gerilim Direği vs.
                            tahminlerde bulunur. Bunların yanı sıra;  elektrik direği üzerinde bulunan uyarı levhası, elektrik direği
                            yakınında bulunan tehlikeli ağaç, elektrik direği yakınında bulunmayan ve herhangi bir tehlike teşkil etmeyen
                            ağaçlar tespit edilir.
                        </p>
                        <h2>Tespit Edilen Objelerin Haritada Üzerinde Konumlandırılması</h2>
                        <p>
                            Görüntüler üzerinde tespit objeler, aracın konumu görüntüye ait bilgiler ve yapay zeka çıktısı
                            kullanılarak, haritada konumlandırılır. Yapılan konumlar objelerin gerçek konumlarına göre santimetre
                            hassasiyetindedir. Kurum/Kişiler kendine özel panellerden bu haritayı görüntüleyebilir.
                        </p>

                        <img src={InfoContentImg} alt=""/>

                        <h2>Entegrasyonlar</h2>
                        <p>Visiosoft envanter yönetim sistemi tüm harita platformlarıyla entegre çalışabilmektedir. </p>

                        <div className="map-integrations">
                            <img src={GoogleMapsIcon} alt="google-maps"/>
                            <img src={EsriIcon} alt="esri"/>
                            <img src={HereIcon} alt="here"/>
                            <img src={MapboxIcon} alt="mapbox"/>
                            <img src={TomtomIcon} alt="tomtom"/>
                        </div>

                        <a href={'https://www.youtube.com/watch?v=GBAM9380Dw8&feature=youtu.be'} target={'_blank'} className={'video'} onClick={()=>props.setClose(false)}>
                            <img src={PlayIcon} alt="play-icon"/>
                            <span>Videoya git</span>
                        </a>

                        <div className={'video-text'}>Enerjisa Envanter Yönetim Sistemi Verilerin Yüklenmesi</div>

                        <div className="footer">
                            <span>© Visiosoft 2022</span>
                            <span>Görüntü işleme ve Yapay Zeka teknolojileri</span>
                            <img src={VisiosoftIcon} alt="logo"/>
                        </div>
                    </div>
                </div>
                <button className={'close-btn'} onClick={()=>props.setClose(false)}>
                {'Kapat '}
                <img src={CloseIcon} alt="close-info"/>
            </button>
            </div>
        </div>
    )
}
export default Info