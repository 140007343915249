import React, {useEffect, useState} from "react";
import {ControlButtonIcon,CloseIcon,SearchButton,DownArrow} from "../../assets/svg/index"
import {fetchHandler, flyHandler} from "../../helper/helper";
import {store} from "../../store/store";
import {useDispatch, useSelector} from "react-redux";
import {CONTROL_PANEL_DATA,OBJECT_DETAIL_OPEN,DRONE_DETAIL_OPEN,CONTROL_PANEL_OPEN} from "../../store/actionsName";

const Types = require(`./../../../config/${process.env.REACT_APP_CONFIG}`).Types;

const ControlSystemComponent = () => {
    const [search,setSearch]=useState('')
    const [features,setFeatures]=useState({page:1,data:[],totalPage:''})
    const [isLoading,setIsLoading]=useState(false)
    const { controlPanelData,controlPanelOpen } = useSelector((state) => state.controlReducer);
    const dispatch = useDispatch();
    const mapCurrent = store.getState().mapReducer.currentMap;
    const getName = (slug) => Types.filter(type => type.className === slug)[0]?.fullName

    const handleClose=()=>{
        dispatch({ type: CONTROL_PANEL_OPEN, payload: false });

        if (mapCurrent.getLayer('layer-symbol-buffer')){
            mapCurrent.removeLayer('layer-symbol-buffer')
        }
        if (mapCurrent.getSource('layer-symbol-buffer')){
            mapCurrent.removeSource('layer-symbol-buffer')
        }
    }

    const clearScreen=()=>{
        dispatch({ type: CONTROL_PANEL_OPEN, payload: true });
        dispatch({ type: OBJECT_DETAIL_OPEN, payload: false });
        dispatch({ type: DRONE_DETAIL_OPEN, payload: false });
      }

    const getControlSystemData = ()=>{
        setIsLoading(true)
        fetchHandler(
            `${process.env.REACT_APP_API_MAPILIO}/api/features/list?token=${process.env.REACT_APP_API_MAPILIO_TOKEN}&organization_key=${process.env.REACT_APP_ORG_KEY}&project_key=${process.env.REACT_APP_PROJECT_KEY}&page=${features.page}`
        ).then(({response}) => {
            response.data.features.forEach(f=>{
                setFeatures(prevState =>({
                    page:prevState.page,
                    data: [...prevState.data.filter(p=>p.feature_id !== f.properties.id),{
                                'feature_id':f.properties.id,
                                'created_at':f.properties.created_at,
                                'feature_fullName':getName(f.properties.class_code),
                                'matchedPoints':getMatchedPoints(f.properties.matchedPoints),
                                'img_urls':getImageUrls(getMatchedPoints(f.properties.matchedPoints)),
                                'featureDetails':f,}],
                    totalPage: Math.ceil(response.total/response.result)
                }))
            })
            features.data.sort((a,b)=>a.feature_fullName.localeCompare(b.feature_fullName))
            setIsLoading(false)
        }).catch(err=>console.log(err))
        dispatch({ type: CONTROL_PANEL_DATA, payload: features.data });
    }

    const getMatchedPoints=(matchedPoints)=>{
        let matchedPointsArray=[]
        matchedPoints.forEach(mp=>{
            matchedPointsArray.push({
                'bbox1':mp.properties.bbox_1,
                'bbox2':mp.properties.bbox_2,
                'img1':mp.properties.imagery_1,
                'img2':mp.properties.imagery_2
            })
        })
        return matchedPointsArray
    }

    const getImageUrls=(matchedPoints)=>{
        const url="https://cdn.mapilio.com/v2/im/extract"
        let imgArray=[]
        matchedPoints.forEach(mp=>{
            imgArray.push({
                'img1_url': `${url}/${mp.img1.uploaded_hash}/${mp.img1.filename}/${mp.bbox1[2]}/${mp.bbox1[3]}/${mp.bbox1[0]}/${mp.bbox1[1]}/400`,
                'img2_url': `${url}/${mp.img2.uploaded_hash}/${mp.img2.filename}/${mp.bbox2[2]}/${mp.bbox2[3]}/${mp.bbox2[0]}/${mp.bbox2[1]}/400`
            })
        })
        return imgArray
    }

    const handleScroll=(e)=>{
        if ((e.target.scrollTop+e.target.offsetHeight+2)>e.target.scrollHeight && features.page<features.totalPage){
            setFeatures(prevState => ({page: features.page+1,data:prevState.data,totalPage:prevState.totalPage}))
        }
    }

    const handleClick=(data)=>{
        flyHandler(data.geometry.coordinates,17)
        if (mapCurrent.getLayer('layer-symbol-buffer')){
            mapCurrent.removeLayer('layer-symbol-buffer')
            mapCurrent.removeImage('layer-symbol-buffer')
        }
        if (mapCurrent.getSource('layer-symbol-buffer')){
            mapCurrent.removeSource('layer-symbol-buffer')
        }
        mapCurrent.addSource("layer-symbol-buffer", {
            type: "geojson",
            data: data,
        });
        mapCurrent.addLayer({
            id:"layer-symbol-buffer",
            type: "symbol",
            source: "layer-symbol-buffer",
            layout: {
                "icon-image": 'layer-symbol-buffer',
                "icon-size": 1,
            }
        });
        mapCurrent.loadImage(
            require(`../../assets/img/selectedObjects/inst-utility-pole-Information.png`),
            (error, image) => {
                if (error) throw error;
                if (image !== undefined) {
                    mapCurrent.addImage('layer-symbol-buffer', image);
                }
            }
        );
    }

    const OrderByName=(e)=>{
        if ( e.target.children[0].classList.value==='rotateImg'){
            e.target.children[0].classList.remove('rotateImg')
        }else{
            e.target.children[0].classList.add('rotateImg')
        }
        controlPanelData.sort((a,b)=> {
            var nameA = a.feature_fullName.toLowerCase(), nameB = b.feature_fullName.toLowerCase();
            if (nameA < nameB) //sort string ascending
                return -1;
            if (nameA > nameB)
                return -1;
            return 0;
        })
        dispatch({ type: CONTROL_PANEL_DATA, payload: controlPanelData });
    }

    useEffect(()=>{
        getControlSystemData()
    },[features.page,controlPanelOpen])

    const ControlTable=({data})=>{
        return(<tr onClick={()=>handleClick(data.featureDetails)}>
            <td>#{data.feature_id}</td>
            <td>{data.feature_fullName}</td>
            <td>
                {new Date(data.created_at).getUTCDay()+'.'+new Date(data.created_at).getUTCMonth()+'.'+new Date(data.created_at).getUTCFullYear()}
            </td>
            <td>
                <div className="photos">
                    <div className="firstPhoto">
                        <img src={data.img_urls[0].img1_url} alt=""/>
                    </div>
                    <div className="secondPhoto">
                        <img src={data.img_urls[0].img2_url} alt=""/>
                    </div>
                    {data.img_urls.length>2 &&
                        <div className="thirdPhoto">
                           +{data.img_urls.length-2}
                        </div>
                    }
                </div>
            </td>
        </tr>)
    }

    return (
        <>
            <div className={'btn-container'}>
                <div className={'btn btn-control'}>
                    <img src={ControlButtonIcon} alt="control-system" onClick={clearScreen}/>
                </div>
            </div>
            <div onScroll={(e)=>handleScroll(e)} className={'control-container'} style={{transform: !controlPanelOpen ? 'translateX(-100%)' : ''}}>
                <button className={'close-btn'} onClick={handleClose}>
                    <img src={CloseIcon} alt="close-btn"/>
                </button>

                <h2 className="title">EnerjiSA Kontrol Sistemi</h2>
                <h3 className="subtitle">Tespit edilen objeler</h3>

                <label>
                    <input className={'control-system'} placeholder={'Id veya obje ara...'} onChange={e=>setSearch(e.target.value.trim())} type="text" name="search" id="search" />
                    <img src={SearchButton} alt=""/>
                </label>

                <table className={'control-data-table'}>
                    <tr>
                        <th>ID</th>
                        <th onClick={(e)=>OrderByName(e)} >ADI <img src={DownArrow} alt="downarrow"/> </th>
                        <th>TARİH <img src={DownArrow} alt="downarrow"/> </th>
                        <th>RESİM</th>
                    </tr>
                    {controlPanelData.filter(d => d.feature_id.toString().includes(search)
                        || d.feature_fullName.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
                        .map((data, i) => <ControlTable data={data} key={i}/>)}
                </table>

                {isLoading && <div>
                    Loading...
                </div>}
            </div>
        </>
    );
};

export default ControlSystemComponent;
