import React from "react";
import Carousel from 'react-multi-carousel';
import AllObjects from '../../../../assets/svg/all-objects.svg';
import {useSelector} from "react-redux";
import 'react-multi-carousel/lib/styles.css';

const Types = require(`./../../../../../config/${process.env.REACT_APP_CONFIG}`).Types;

const ReportModalSlider = () => {
	const {reports} = useSelector(state => state.reportReducer);

	const getName = (slug) => Types.filter(type => type.className === slug)[0]?.fullName
	const getIcon = (slug) => Types.filter(type => type.className === slug)[0]?.iconName.default

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 4.5
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 4.5
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2.75
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1.25
		}
	};

	return (
		<div className={"report-modal-content-slider"}>
			{
				reports?.data && <Carousel className={"report-modal-content-slider-items"} responsive={responsive} arrows={false} swipeable={true}>
						<div className={"report-modal-content-slider-item"}>
							<div className={"image-container"}>
								<img src={AllObjects} alt=""/>
							</div>
							<div className={"report-modal-content-slider-item-info"}>
								<p className={"title"}>Tüm Objeler</p>
								<p className={"subtitle"}>{ reports.total_class }</p>
							</div>
						</div>

					{
						reports.data.map((report, index) => {
							return (
								<div className={"report-modal-content-slider-item"} key={index}>
									<div className={"image-container"}>
										<img src={getIcon(report.class_code)} alt=""/>
									</div>
									<div className={"report-modal-content-slider-item-info"}>
										<p className={"title"}>{getName(report.class_code)}</p>
										<p className={"subtitle"}>{report.count}</p>
									</div>
								</div>
							);
						})
					}
				</Carousel>
			}
		</div>
	);
};

export default ReportModalSlider;
