import React, {useEffect, useState} from "react";
import ClearIcon from "../../assets/svg/clear.svg";
import { useDispatch } from "react-redux";

const Types = require(`./../../../config/${process.env.REACT_APP_CONFIG}`).Types;

const ObjectDetailDrone = ({ selectedImage, selectedDetail, setOpen }) => {
    const dispatch = useDispatch()
    let imageUrl=selectedImage[0]
    const [imgIndex,setImageIndex]=useState(0)
    const data =JSON.parse(selectedDetail.attributes).attributes
    const getName = () => {
        const code = selectedDetail.type
        return Types.filter(type => type.className === code)[0]?.fullName
    }

    const getIcon = () => {
        const code = selectedDetail.type
        return Types.filter(type => type.className === code)[0]?.iconName.default
    }

    const changeImgUrl=(index)=>{
        // setImageUrl(selectedImage[index])
        setImageIndex(index)
    }

    return (
    <div className={"detail-container"}>
      <div
        style={{
          padding: "0 15px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p style={{ color: "#C0C3C8", marginTop: "0 !important" }}>
          Object detail
        </p>
        <img
          src={ClearIcon}
          alt={"Close button"}
          style={{ cursor: "pointer" }}
          onClick={() =>dispatch({ type: 'DRONE_DETAIL_OPEN', payload: false })}
        />
      </div>
      <div style={{ width: "100%", height: "410px", position:"relative"}}>
        <img
          src={imageUrl}
          alt={"Object image"}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
          <div style={{position:"absolute",bottom:'20px',right:'20px',display:"flex",gap:'5px'}}>
              {selectedImage.map((imgPaginate,index)=>
                  <div key={index} onClick={()=>changeImgUrl(index)} style={{
                      color:imgIndex === index ? 'white':'black',
                      backgroundColor:imgIndex === index ? 'black':'white',
                      borderRadius:'50%',
                      width:'25px',
                      textAlign:'center',
                      cursor:'pointer',
                      userSelect:'none',
                      border:`1.5px solid ${imgIndex === index ? 'white':'black'}`,
                      height:'25px'}}>
                      {index+1}
                  </div>
              )}
          </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 15px",
        }}
      >
          <div style={{display: "flex", width:'100%',flexDirection: "column", position: "relative", padding: "10px", flex: 1}}>
              <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={getIcon()} alt={"Object type"} style={{height: "30px",}}/>
                  <span style={{textTransform: "capitalize"}}>{getName()}</span>
              </div>
              <div style={{
                  display: "inline-flex",
                  flexDirection: "column",
                  color: "#3D3D3D",
                  fontSize: "14px",
                  margin: ".5rem 0",
              }}>
                  <span style={{ display: "flex", flexWrap: 'wrap'}}>
                      <span>Sorun: {data[1].has_risk ? data[1].message : 'Yok'}</span>
                  </span>
              </div>
              <span style={{
                      fontSize: "14px",
                      color: "#C0C3C8",
                      marginTop: "4px",
                      marginBottom: "10px",
                  }}>
                  Rapor: {data[2]?.message}
              </span>
              <span
                  style={{
                      padding: "5px 10px",
                      backgroundColor: "#75B505",
                      borderRadius: "16px",
                      fontSize: "12px",
                      color: "#fff",
                      height: "min-content",
                      whiteSpace: "nowrap",
                      position: "absolute",
                      top: "10px",
                      right: "15px",
                  }}
              >
          Doğruluk % {data[0].accuracy}
        </span>
          </div>
      </div>
    </div>
  );
};

export default ObjectDetailDrone;
